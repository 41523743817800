.mat-select-search-option {
	position: sticky !important;
	top: 0;
	z-index: 1;
	opacity: 1;
	pointer-events: all;

	&--no-results {
		height: 6em !important;
	}
}