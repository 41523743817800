@import '@shared/colors';

.mat-icon-button {
	color: $neutral030;

	/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
	& svg {
		fill: $neutral030;
	}
}
