.refreshing-box {
    height: calc(100% - 100px);
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 2em;
    color: rgba(0, 0, 0, 0.356);
    font-weight: 700;
}