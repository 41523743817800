// Some useful links about colors in design system:
// https://uxdesign.cc/how-should-you-name-your-colors-in-a-design-system-3086513476df
// https://m3.material.io/styles/color/the-color-system/key-colors-tones
//

// Brand color rgb(26, 115, 232)
$primary010: rgb(6, 27, 55);
$primary020: rgb(11, 48, 96);
$primary030: rgb(16, 69, 139);
$primary040: rgb(21, 92, 185);
$primary050: rgb(26, 115, 232);
$primary060: rgb(76, 146, 237);
$primary070: rgb(122, 174, 242);
$primary080: rgb(167, 201, 246);
$primary090: rgb(211, 228, 251);
$primary095: rgb(233, 242, 253);

// Brand color rgb(255, 202, 94)
$secondary060: rgb(176, 139, 65);
$secondary070: rgb(208, 165, 77);
$secondary080: rgb(241, 191, 89);
$secondary085: rgb(255, 202, 94);
$secondary090: rgb(255, 223, 157);
$secondary095: rgb(255, 239, 207);
$secondary095: rgb(255, 249, 236);

$tertiary010: rgb(26, 28, 30);
$tertiary020: rgb(46, 48, 52);
$tertiary025: rgb(66, 69, 74);
$tertiary030: rgb(68, 71, 76);
$tertiary040: rgb(91, 94, 102);
$tertiary050: rgb(114, 119, 129);
$tertiary060: rgb(141, 145, 153);
$tertiary070: rgb(168, 171, 178);
$tertiary080: rgb(196, 198, 203);
$tertiary085: rgb(211, 213, 216);
$tertiary088: rgb(221, 221, 221);
$tertiary090: rgb(225, 226, 229);
$tertiary095: rgb(240, 241, 242);

$neutral000: rgb(0, 0, 0);
$neutral010: rgb(26, 26, 26);
$neutral020: rgb(51, 51, 51);
$neutral030: rgb(77, 77, 77);
$neutral040: rgb(102, 102, 102);
$neutral045: rgb(106, 106, 106);
$neutral050: rgb(128, 128, 128);
$neutral060: rgb(153, 153, 153);
$neutral070: rgb(179, 179, 179);
$neutral080: rgb(204, 204, 204);
$neutral085: rgb(217, 217, 217);
$neutral090: rgb(230, 230, 230);
$neutral095: rgb(242, 242, 242);
$neutral098: rgb(250, 250, 250);
$neutral100: rgb(255, 255, 255);

$statusError: rgb(244, 67, 54);
$statusErrorDark: rgb(190, 48, 48);
$statusOk: rgb(17, 197, 137);

// Other
$importantAccent: rgb(250, 128, 114);
