@import '@shared/colors';

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #ffca5e !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb,
.mat-slide-toggle .mat-slide-toggle-thumb {
    border: 1px solid $tertiary025 !important;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
    border: 1px solid $tertiary010 !important;
}

.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle .mat-slide-toggle-bar {
    background-color: $tertiary030 !important;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: $tertiary095 !important;
}
