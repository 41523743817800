@import '@shared/variables';

$navbar-mobile-width: 1000px;

#bottom-container-dialog {
	display: flex !important;
	flex-direction: column !important;
	width: 100% !important;

	@media screen and (min-width: $navbar-mobile-width) {
		flex-direction: row !important;
	}
}

@media screen and (max-width: $md) {
	// bootstrap and angular material
	.modal-dialog {
		min-width: 382px !important;
		max-width: 100% !important;
		max-height: 100% !important;
		margin: 1.75rem auto !important;
	}

	.modal-content {
		height: 100% !important;
		min-height: 100% !important;
		max-height: 100% !important;
	}

	.mat-dialog-container {
		margin: 0 20px !important;
	}
}
