.grid-tooltip,
.text-tooltip {
	font-size: $font-size-grid;
	opacity: 1 !important;
	padding: 0.2rem;
}

.marker-tooltip {
	font-size: 0.7rem;
	opacity: 1 !important;
	width: auto;
	z-index: 500;

	hr {
		margin-top: 0.2rem;
		margin-bottom: 0.2rem;
	}
}

.grid-tooltip-inner,
.marker-tooltip-inner {
	border-radius: 0;
	background-color: $tertiary020;
	color: $neutral100;
}

.text-tooltip-inner {
	border-radius: 0;
	background-color: $tertiary020;
	color: $neutral100;
}

.filter-tooltip {
	font-size: 0.7rem;
	opacity: 1 !important;
	padding: 0.2rem;
}

.filter-tooltip-inner {
	border-radius: 0;
	background-color: $tertiary020;
	color: $neutral100;
}

.chart-tooltip-desc {
	color: $neutral050;
}

.chart-tooltip {
	font-size: 0.8em;
}
