//// Normalization of HTML elements, manually forked from Normalize.css to remove
//// styles targeting irrelevant browsers while applying new styles.
////
//// Normalize is licensed MIT. https://github.com/necolas/normalize.css

*,
*::before,
*::after {
	box-sizing: border-box;
}

main {
	display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

a {
	background-color: transparent;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: bolder;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

img {
	border-style: none;
	vertical-align: middle;
	border-style: none;
}

button:focus:not(:focus-visible) {
	outline: 0;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
	margin: 0;
	font-family: inherit;
	line-height: inherit;
  }

  button,
  input {
	overflow: visible;
  }

  button,
  select {
	text-transform: none;
  }

  [role="button"] {
	cursor: pointer;
  }

  select {
	word-wrap: normal;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
  }

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}


[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}


[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
}

details {
	display: block;
}

summary {
	display: list-item;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0 !important;
}

abbr[title],
abbr[data-original-title] { // 1
  text-decoration: underline; // 2
  text-decoration: underline dotted; // 2
  cursor: help; // 3
  border-bottom: 0; // 4
  text-decoration-skip-ink: none; // 5
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

b,
strong {
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

label {
  display: inline-block;
  margin-bottom: 8px;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit; // 2
  -webkit-appearance: button; // 1
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
