.mat-pseudo-checkbox::after,
.mat-pseudo-checkbox-disabled {
    color: #42454a !important;
}

.mat-pseudo-checkbox-checked {
    background: #ffca5e !important;
}

.mat-pseudo-checkbox-disabled::after {
    color: #fff !important;
}