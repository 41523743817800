@import '@shared/colors';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import './mat-select';
@import './mat-checkbox';
@import './mat-slide-toggle';
@import './mat-datepicker';
@import './mat-expansion-panel';
@import './mat-snackbar';

.badge {
	background-color: $neutral045;
	border-radius: 40% !important;
	width: 20px;
	height: 20px !important;
	text-align: center;
	padding-top: 0 !important;
	padding: 5% 5% !important;
	position: relative;
	right: -18px;
	top: -42px;
	float: right;
	display: block;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
	z-index: 5000 !important;
}

.mat-checkbox-layout {
	margin-bottom: 8px;
}
