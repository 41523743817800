@import '@shared/colors';
@import "~@ag-grid-community/styles/ag-grid.css";
@import "~@ag-grid-community/styles/ag-theme-balham.css";
@import "~@ag-grid-community/styles/ag-theme-material.css";

.ag-header-cell,
.ag-cell {
	cursor: pointer;
}

.ag-theme-balham {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
	background-color: #eff0f3;

	.ag-root,
	.ag-root-wrapper {
		border: none !important;
		background-color: #eff0f3;
	}

	.ag-header-cell,
	.ag-cell {
		padding-left: 4px;
		padding-right: 0;
		cursor: pointer;
	}

	.ag-header-row {
		border-width: 0;
	}

	.ag-header-cell::after {
		border: none;
		width: 0 !important;
	}

	.ag-cell-not-inline-editing {
		border: none;
	}

	.ag-group-cell {
		background-color: red;
	}
}

.grid-base {
	.ag-header {
		background-color: $tertiary020;
		color: $neutral100;
		border-bottom: solid 4px transparent;
		font-size: $font-size-header;
	}

	.ag-row {
		border: none;
		color: $neutral040;
		font-size: $font-size-grid;
		background-color: $neutral095;
	}

	.ag-row-odd {
		border: none;
		background-color: white;
	}

	.ag-ltr .ag-cell-focus {
		border: none;
	}

	.btn-in-grid {
		padding: 0;
		background-color: $neutral090;
		border: none;
		width: 30px;
		height: 100%;
		border-radius: 0;
		margin-top: -2px;

		&:hover {
			background-color: $neutral095;
		}

		&:disabled {
			background-color: $neutral095;
		}
	}

	.grid-icon {
		height: 18px;
	}

	.sparkline {
		fill: none;
		stroke: #000;
		stroke-width: 0.5px;
	}

	div.ag-root .ag-cell-focus {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}
}

.pagination-form {
	width: 70px !important;
}

#data-grid .ag-header {
	background-color: #eff0f3 !important;
	color: black !important;
}

#data-grid .ag-row {
	border-radius: 1px !important;
	padding: 0 22px 0 22px !important;
}

#data-grid .ag-cell-focus {
	border: none !important;
}

tra-grid-base .ag-row-hover::before {
	content: none !important;
}
