
@import '@shared/colors';

.mat-snack-bar-container {
	color: $neutral100;
	background: $tertiary025;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
	line-height: 1;
	font-family: inherit;
	font-size: inherit;
	font-weight: 500;
}

.mat-simple-snack-bar-content {
	white-space: pre-wrap;
}

.error-snackbar {
	background: $statusErrorDark;

	& button {
		background: $statusErrorDark;
		color: $neutral100;
		font-weight: bold;

		&:hover {
			background: inherit;
		}
	}
}

.success-snackbar {
	background: $statusOk;

	& button {
		background: $statusOk;
		color: $neutral100;
		font-weight: bold;

		&:hover {
			background: inherit;
		}
	}
}

.info-snackbar {
	background: $primary060;

	& button {
		background: $primary060;
		color: $neutral100;
		font-weight: bold;

		&:hover {
			background: inherit;
		}
	}
}
