.btn {
	&--primary {
		padding: 6px 12px;
		color: $neutral100;
		background-color: $primary050;
		border: 1px solid $primary050;

		&:hover {
			color: $neutral100;
			background-color: $primary070;
			border-color: $primary070;
		}

		&:active {
			color: $neutral100;
			background-color: $primary040;
			border-color: $primary040;
		}

		&:disabled {
			opacity: 0.5;
			background-color: $primary050;
			border-color: $primary050;
			pointer-events: none;
		}

		&-small {
			padding: 3px 6px;
		}

		&-wider {
			min-width: 150px;
		}
	}

	&--secondary {
		padding: 6px 12px;
		color: $tertiary040;
		background-color: $secondary085;
		border: 1px solid $secondary085;

		&:hover {
			background-color: $secondary090;
			border-color: $secondary090;
			color: $tertiary030;
		}

		&:active {
			background-color: $secondary080;
			border-color: $secondary080;
		}

		&:disabled {
			background-color: $secondary085;
			opacity: 0.5;
			pointer-events: none;
		}

		&-small {
			padding: 5px 12px;
			font-size: 14px;
		}

		&-wider {
			min-width: 150px;
		}
	}

	&--tertiary {
		padding: 6px 6px;
		border: 1px solid transparent;
		color: $tertiary040;

		&:hover {
			color: $tertiary060;
		}

		&:active {
			color: $tertiary020;
		}

		&:disabled {
			color: $tertiary040;
			opacity: 0.5;
			pointer-events: none;
		}

		&-border {
			border-color: $tertiary085;

			&:hover {
				border-color: $tertiary085;
			}

			&:active {
				border-color: $tertiary050;
			}

			&:disabled {
				border-color: $tertiary085;
			}
		}

		&-wider {
			min-width: 150px;
		}
	}
}
