.mat-calendar-body-cell-content.mat-calendar-body-selected {
	background-color: $secondary085;
	color: $tertiary030;

    &:hover {
        pointer-events: none;
    }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $tertiary070;
}

.datepicker i.fa-calendar-alt {
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        color: $secondary080;
    }
}